.nav{
    position: fixed;
    width: 100%;
    padding: 20px;
    margin-bottom: 50px;
    height: 70px;
    transition: linear all 0.2s;
    z-index: 100;
    backdrop-filter: blur(10px);

}
.nav-show{
    background-color: #48BB78;
    height: 80px;
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 25px;
}
.mobilenav{
    position: fixed;
    width: 100%;
    padding: 10px;
    margin-bottom: 50px;
    height: 80px;
    transition: linear all 0.2s;
    z-index: 100;
    overflow: hidden;

}
.mobilenav-show{
    transition: ease-in-out all 0.5s;
    background-color: #48BB78;
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}
.mobilenav-active{
    transition: ease-in-out all 0.5s;
    background-color: #2D3748;
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    height: 100%;
}
*{
    margin: 0;
}
  
  @keyframes fade {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }

.mobilenav-active .navlink{
    opacity: 1;
}
.navlink{
    opacity: 0;
    transition: all ease-in-out 0.5;
}
.navlink:hover{
    transform: translateY(-10px);
}
.normalnavlink{
    transition: all ease-in-out 0.5;

}
.normalnavlink:hover{
    transform: translateY(-3px);
}
